<template>
    <div class="institution-events" v-if="contentDataSet.data!=null && contentDataSet.total>0">
        <div class="col-12">
            <h4 class="title">Veranstaltungen dieses Anbieters</h4>
        </div>
        <div class="scroll-container">
            <CardEvent :class="'card-event'" v-for="content in contentDataSet.data" :key="content.id" :content="content" :institution-prop="institution" :width="'200px'"/>
        </div>
        <pagination
        v-if="contentDataSet.total>6"
        :page-count="contentDataSet.meta.last_page"
        :click-handler="changePage"
        containerClass="pagination"
        prev-text="&laquo"
        next-text="&raquo"/>
    </div>
</template>
<script>
    import Pagination from '@/components/controls/Pagination';
    import CardEvent from '@/components/cards/CardEvent.vue';
    import { getCustomInstitutionContents } from '@/api/institution'
    import { showLoader, hideLoader } from '@/utils/helpers';

    export default {
        name: "institutionEvents",
        components: {
            CardEvent,
            Pagination
        },
        props: {
            institution: {
                type: Object,
                required: true
            },
        },
        emits: ["changePage"],
        data(){
            return{
                loader: null,
                contentDataSet: {
                  total: 0,
                  data: null,
                  meta:null,
                  query: {
                    page:1,
                    limit: 6,
                    keyword: '',
                    type: 'view',
                    selectedAppId: this.$appId,
                    selectedContentTypeId: this.$offerId,
                    view_status: 1,
                    sort: {
                      prop: '',
                      order: 'asc'
                  },
              },
          },
      }
  },
  created() {
    this.getInstitutionContents();
},
methods: {
    showLoader,
    hideLoader,
    changePage(page){
        this.contentDataSet.query.page = page;
        this.getInstitutionContents();
    },
    getInstitutionContents() {
        this.loader = this.showLoader(this.loader);
        const { limit, page } = this.contentDataSet.query;
        getCustomInstitutionContents(this.institution.id,this.contentDataSet.query).then(response => {
          var data = response.data;
          var meta = response.meta;
          this.contentDataSet.data = data;
          this.contentDataSet.data.forEach((element, index) => {
            element['index'] = (page - 1) * limit + index + 1;
        });
          this.contentDataSet.meta = meta;
          this.contentDataSet.total = meta.total;
      }).catch(error => {
          console.log(error);
      }).finally(() => {
        this.loader = this.hideLoader(this.loader);
    });
  },
},
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .institution-events{
        padding-left: 0px!important;
        padding-right: 0px!important;
    }

    .institution-events .card-event{
        width: 200px!important;
        min-width: 200px!important;
        margin-right: 15px!important;    
    }

    .institution-events .card-event .card-heading{
        border-bottom-right-radius: 0px!important;
        border-bottom-left-radius: 0px!important;
    }

    .institution-events .card-event{
        margin-bottom: 20px!important;
        max-width: 215px;
        min-width: auto;
        width: 215px;
    }

    .institution-events .title{
        padding-left: calc(var(--bs-gutter-x) / 2);
    }

    .institution-events .card-event:nth-child(3n) {
        margin-right: 0px!important;

        @media (max-width: 992px) {
            margin-right: 15px!important;
        }
    }


</style>
